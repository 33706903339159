// For Nuxt 3
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import {
  faUser,
  faSearch,
  faBars,
  faX,
  faPlay,
  faHandHoldingDollar,
  faLocationDot,
  faArrowRight,
  faArrowLeft,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faClose,
  faFile,
  faLink,
  faLinkSlash,
  faImage,
  faSpinner,
  faPen,
  faFileInvoiceDollar,
  faGear,
  faTriangle,
  faEnvelope,
  faCirclePlus,
  faXmarkCircle,
  faTick,
  faCheck,
  faMinus,
  faPlus,
  faCreditCard,
  faTrash,
  faStar,
  faMapLocationDot,
  faGripVertical,
  faArrowLeftFromBracket,
  faList,
  faListOl,
  faH1,
  faH2,
  faH3,
  faBold,
  faItalic,
  faStrikethrough,
  faParagraph,
  faBlockQuote,
  faHorizontalRule,
  faTurnDownLeft,
  faUndo,
  faRedo,
  faBroomWide,
  faFileArrowUp,
  faFilePen,
  faBug,
  faExternalLinkAlt,
  faClipboard,
  faBan,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faObjectsAlignCenterHorizontal,
  faPenToSquare,
} from "@fortawesome/pro-solid-svg-icons";

import { faXTwitter, faInstagram, faFacebook, faLinkedin, faGoogle } from "@fortawesome/free-brands-svg-icons";

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  faUser,
  faSearch,
  faBars,
  faX,
  faPlay,
  faHandHoldingDollar,
  faLocationDot,
  faArrowRight,
  faArrowLeft,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faXTwitter,
  faInstagram,
  faFacebook,
  faLinkedin,
  faGoogle,
  faClose,
  faFile,
  faLink,
  faLinkSlash,
  faImage,
  faSpinner,
  faPen,
  faFileInvoiceDollar,
  faGear,
  faTriangle,
  faEnvelope,
  faCirclePlus,
  faXmarkCircle,
  faTick,
  faCheck,
  faMinus,
  faPlus,
  faCreditCard,
  faTrash,
  faStar,
  faMapLocationDot,
  faGripVertical,
  faArrowLeftFromBracket,
  faH1,
  faH2,
  faH3,
  faList,
  faListOl,
  faBold,
  faItalic,
  faStrikethrough,
  faParagraph,
  faBlockQuote,
  faHorizontalRule,
  faTurnDownLeft,
  faUndo,
  faRedo,
  faBroomWide,
  faFileArrowUp,
  faFilePen,
  faBug,
  faExternalLinkAlt,
  faClipboard,
  faBan,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faObjectsAlignCenterHorizontal,
  faPenToSquare
);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon, {});
  nuxtApp.vueApp.component("fa-layers", FontAwesomeLayers, {});
  nuxtApp.vueApp.component("fa-icon", FontAwesomeIcon, {});
});

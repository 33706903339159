import { default as indexwtBglxzFiwMeta } from "/codebuild/output/src1066653785/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45invitew8GMugbw0WMeta } from "/codebuild/output/src1066653785/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchaseQoUoR8ejxdMeta } from "/codebuild/output/src1066653785/src/pages/account/extra-purchase.vue?macro=true";
import { default as indexDLmcWXWGkFMeta } from "/codebuild/output/src1066653785/src/pages/account/index.vue?macro=true";
import { default as settingsfUnQ0X5NFbMeta } from "/codebuild/output/src1066653785/src/pages/account/settings.vue?macro=true";
import { default as subscriptionNdRVGUwEDvMeta } from "/codebuild/output/src1066653785/src/pages/account/subscription.vue?macro=true";
import { default as transactionslKzSfCEvEUMeta } from "/codebuild/output/src1066653785/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscription6BtOnSOU2vMeta } from "/codebuild/output/src1066653785/src/pages/account/update-subscription.vue?macro=true";
import { default as previewGHLvy3eDB0Meta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composeaMUEy6Xhn1Meta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as index0KPMUm6bwTMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emailsQDgoBH7RXrMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as edithpJsYLCwG7Meta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsaF290juIAQMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as indexln3eVQbve7Meta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsFOJZQMT8t6Meta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesU5uLIImUn9Meta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as galleryiR2ika8zR7Meta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsnBSRexgwXlMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingsHLnGN7YLGHMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indexv2koaDsu1iMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as membersTFl8vamomIMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editdxzkWxnhUxMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as indexwE9J6UOdHdMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newsB2sD1yb43NMeta } from "/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93tITDx8fSJNMeta } from "/codebuild/output/src1066653785/src/pages/donate/[slug].vue?macro=true";
import { default as indexOL0G2jD5FNMeta } from "/codebuild/output/src1066653785/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribephxiO0FXS6Meta } from "/codebuild/output/src1066653785/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_93I3NE4QeqevMeta } from "/codebuild/output/src1066653785/src/pages/events/[slug].vue?macro=true";
import { default as indexPeg09ASeRtMeta } from "/codebuild/output/src1066653785/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordLA1rOa0B2oMeta } from "/codebuild/output/src1066653785/src/pages/forgot-password.vue?macro=true";
import { default as filesOgAo5pXlZtMeta } from "/codebuild/output/src1066653785/src/pages/groups/[slug]/files.vue?macro=true";
import { default as gallery5NdVCpPuSxMeta } from "/codebuild/output/src1066653785/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexqNSuajSIaFMeta } from "/codebuild/output/src1066653785/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shoprgJkbk5oabMeta } from "/codebuild/output/src1066653785/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_935KFzhrrc3JMeta } from "/codebuild/output/src1066653785/src/pages/groups/[slug].vue?macro=true";
import { default as indexewnDd5Tc75Meta } from "/codebuild/output/src1066653785/src/pages/groups/index.vue?macro=true";
import { default as indexVJkSI8NwWiMeta } from "/codebuild/output/src1066653785/src/pages/index.vue?macro=true";
import { default as joinriQXzKtJGCMeta } from "/codebuild/output/src1066653785/src/pages/join.vue?macro=true";
import { default as login5f6TmPsoANMeta } from "/codebuild/output/src1066653785/src/pages/login.vue?macro=true";
import { default as confirmationbDfpArSxWlMeta } from "/codebuild/output/src1066653785/src/pages/membership/confirmation.vue?macro=true";
import { default as indexqNc2yAPhZUMeta } from "/codebuild/output/src1066653785/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93BPGjCjE1wHMeta } from "/codebuild/output/src1066653785/src/pages/news/[slug].vue?macro=true";
import { default as indexKCToKBwGBVMeta } from "/codebuild/output/src1066653785/src/pages/news/index.vue?macro=true";
import { default as reset_45password6fnC9S2ytdMeta } from "/codebuild/output/src1066653785/src/pages/reset-password.vue?macro=true";
import { default as setup_452faT2WlpyIJ1oMeta } from "/codebuild/output/src1066653785/src/pages/setup-2fa.vue?macro=true";
import { default as shopiXUN4CSeVJMeta } from "/codebuild/output/src1066653785/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src1066653785/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src1066653785/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchaseQoUoR8ejxdMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexDLmcWXWGkFMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsfUnQ0X5NFbMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionNdRVGUwEDvMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionslKzSfCEvEUMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscription6BtOnSOU2vMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/account/update-subscription.vue")
  },
  {
    name: emailsQDgoBH7RXrMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emailsQDgoBH7RXrMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewGHLvy3eDB0Meta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composeaMUEy6Xhn1Meta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: index0KPMUm6bwTMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventsFOJZQMT8t6Meta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsFOJZQMT8t6Meta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: edithpJsYLCwG7Meta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsaF290juIAQMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: indexln3eVQbve7Meta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesU5uLIImUn9Meta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: galleryiR2ika8zR7Meta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsnBSRexgwXlMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingsHLnGN7YLGHMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexv2koaDsu1iMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: membersTFl8vamomIMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: newsB2sD1yb43NMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newsB2sD1yb43NMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editdxzkWxnhUxMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: indexwE9J6UOdHdMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src1066653785/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src1066653785/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src1066653785/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src1066653785/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src1066653785/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src1066653785/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_935KFzhrrc3JMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src1066653785/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src1066653785/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src1066653785/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src1066653785/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src1066653785/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src1066653785/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1066653785/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src1066653785/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src1066653785/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src1066653785/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexqNc2yAPhZUMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src1066653785/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src1066653785/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src1066653785/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452faT2WlpyIJ1oMeta || {},
    component: () => import("/codebuild/output/src1066653785/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src1066653785/src/pages/shop.vue")
  }
]